import styled from 'styled-components'
import { Button, Heading, Text, LogoIcon } from '@pancakeswap/uikit'
import Page from 'components/Layout/Page'
import { useTranslation } from '@pancakeswap/localization'
import Link from 'next/link'

const StyledNotFound = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 64px);
  justify-content: center;
`
const Logo = styled('img')`
  width: 150px;
`
const NotFound = ({ statusCode = 404 }: { statusCode?: number }) => {
  const { t } = useTranslation()

  return (
    <Page>
      <StyledNotFound>
        <Logo src="/images/tokens/0x679f6863a653251C8C215e77205A7058b5bF676a.png" />
        <Heading scale="xl" style={{ marginBottom: '50px' }}>
          {t('Oops, page not found.')}
        </Heading>
        <Link href="/" passHref>
          <Button as="a" scale="sm">
            {t('Back Home')}
          </Button>
        </Link>
      </StyledNotFound>
    </Page>
  )
}

export default NotFound
